import type { PropsWithChildren } from 'react';
import React from 'react';

import type { AppState } from '@auth0/auth0-react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

interface Auth0ProviderWithHistoryProps {
  readonly children: React.ReactNode;
}

function stripTrailingSlash(str: string | undefined): string | undefined {
  if (str) {
    return str.replace(/\/$/, '');
  }
}

const Auth0ProviderWithHistory = ({
  children
}: PropsWithChildren<Auth0ProviderWithHistoryProps>) => {
  const domain = stripTrailingSlash(process.env.REACT_APP_GRAPHENE_AUTH0_DOMAIN);

  const clientId = process.env.REACT_APP_GRAPHENE_AUTH0_CLIENT_ID;

  const audience = process.env.REACT_APP_GRAPHENE_AUTH0_AUDIENCE;

  const navigate = useNavigate();

  if (!(domain && clientId)) {
    return null;
  }

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo ?? '/');
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      authorizationParams={{
        redirect_uri: window.location.origin,
        ...(process.env.NODE_ENV === 'production' && {
          cookieDomain: process.env.REACT_APP_COOKIE_DOMAIN
        }),
        audience
      }}
      onRedirectCallback={onRedirectCallback}
      {...(process.env.NODE_ENV === 'production' && {
        cookieDomain: process.env.REACT_APP_COOKIE_DOMAIN,
        useCookiesForTransactions: true,
        legacySameSiteCookie: true
      })}
      {...(process.env.NODE_ENV !== 'production' && { cacheLocation: 'localstorage' })}>
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
